<template>
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19.1131 31.3503C18.4163 32.1391 17.2035 32.2211 16.4042 31.5334L4.6583 21.4282C4.24005 21.0684 4 20.5476 4 20C4 19.4524 4.24005 18.9316 4.6583 18.5718L16.4042 8.46656C17.2035 7.77892 18.4163 7.86089 19.1131 8.64966C19.8099 9.43844 19.7269 10.6353 18.9276 11.323L11.0441 18.1053L34.08 18.1053C35.1404 18.1053 36 18.9536 36 20C36 21.0464 35.1404 21.8947 34.08 21.8947L11.0441 21.8947L18.9276 28.677C19.7269 29.3647 19.8099 30.5616 19.1131 31.3503Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ArrowLeftIcon',
    };
</script>
