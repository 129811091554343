<template>
    <div class="page-not-found">
        <logo-outlined class="page-not-found__bg-logo" />

        <div class="page_not-found__content">
            <img ref="status" class="page-not-found__status" src="~@/assets/images/404.png" alt="404" />

            <div class="page-not-found__text">
                <h1>{{ $t('page_not_found.title') }}...</h1>

                <p>{{ $t('page_not_found.description') }}</p>

                <router-link :to="{ name: 'home' }">
                    <text-button>
                        <arrow-left-icon />
                        {{ $t('page_not_found.button') }}
                    </text-button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon';
    import LogoOutlined from '@/components/icons/LogoOutlined';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'PageNotFound',
        components: {
            ArrowLeftIcon,
            LogoOutlined,
            TextButton,
        },
        mounted() {
            const resizeObserver = new ResizeObserver(
                entries => (entries[0].target.style.marginBottom = window.innerWidth >= 640 ? `-${0.2675 * entries[0].contentRect.height}px` : 0)
            );

            resizeObserver.observe(this.$refs.status);
        },
    };
</script>

<style lang="scss" scoped>
    .page-not-found,
    .page_not-found__content,
    .page-not-found__text {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .page-not-found {
        height: 100%;
        background: radial-gradient(41.41% 73.61% at 52.45% -4.26%, rgba(59, 72, 111, 0.2) 0%, rgba(14, 17, 26, 0.2) 100%), #0e111a;
        overflow: hidden;
    }

    .page-not-found__bg-logo {
        position: absolute;
        max-width: 110rem;
        width: 200%;
        z-index: 0;

        ::v-deep path {
            stroke: rgba(188, 210, 228, 0.03);
        }
    }

    .page_not-found__content {
        z-index: 1;
    }

    .page-not-found__status {
        max-width: 85rem;
        width: 100%;
        margin: 0 0 0 6.3%;
    }

    .page-not-found__text {
        max-width: 53rem;
        margin: 0 1.5rem;
        text-align: center;

        h1 {
            color: #ffffff;
            font-family: 'Gotham Black', sans-serif;
            font-size: 3.8rem;
            font-weight: 450;
        }

        p {
            margin: 1.5rem 0 3.5rem;
            color: #cecfd6;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.4rem;
            font-weight: 325;
            line-height: 1.4;
        }

        button {
            width: 24rem;
        }
    }

    @media screen and (min-width: 64em) {
        .page-not-found__text {
            h1 {
                font-size: 4.4rem;
            }

            p {
                font-size: 1.6rem;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .page-not-found__status {
            max-width: 95rem;
        }

        .page-not-found__text {
            h1 {
                font-size: 5rem;
            }

            p {
                font-size: 1.8rem;
            }
        }
    }
</style>
